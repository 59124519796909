<script lang="ts">
	export let href: string | null = null
	export let condition: boolean | null = null
</script>

{#if condition}
	<a {href}>
		<slot />
	</a>
{:else}
	<slot />
{/if}
